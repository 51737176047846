import React, { useState, useEffect } from "react"
import HTML from "../components/HTML"
import Layout from '../components/layout'
import SEO  from '../components/SEO'
import VDPRecommended from "../components/vdp/VDPRecommended"
import '../scss/srp-side-style.scss'
import '../scss/srp-fonts.scss'
import '../scss/srp-normalize.scss'
import { FinanceCalculatorProvider } from "../components/modal/financing/storage/CalculatorContext"
import VDPModalContainer from "../components/vdp/VDPModalContainer"

function InventoryHTML(props) {
  const { pageContext } = props;
  const { pageContent, theme, dealerInfo, headerTags, vehicle, decimalSpaceHide, ShowLocationFilter, strikeMSRP, site_id, lead_url, gtmId } = pageContext;
  const {vdpslug,vehiclecardbutton, unavailableImg, fallbackText, useCompactCards, AutoCheckEnabled, downpayment, disclaimerCalculator, disclaimerContact, disclaimerFinanceApp } = pageContext.inventorymanagement;
  const [extraData, setExtra] = useState({})

  const rates = [
    Number(pageContext.inventorymanagement.aRate),
    Number(pageContext.inventorymanagement.bRate),
    Number(pageContext.inventorymanagement.cRate),
    Number(pageContext.inventorymanagement.dRate),
    Number(pageContext.inventorymanagement.eRate),
    Number(pageContext.inventorymanagement.fRate),
  ]
  const defaultMonth = Number(pageContext.inventorymanagement.months);

  const location = typeof window !== 'undefined' && window.location
  const history = typeof window !== 'undefined' && window.history
  const SearchParam = location.search

  let components = [ 
    <div className="vehicle-card-containter" data={pageContent.InventoryCards} style={{margin: '2em'}}>
      <VDPRecommended data={pageContent.InventoryCards} recommendation={vehicle} slug={vdpslug}
      buttons={vehiclecardbutton} unavailableImg={unavailableImg} AutoCheckEnabled={AutoCheckEnabled}
      catchActionData={setExtra} fallbackText={fallbackText} ShowLocationFilter={ShowLocationFilter} decimalSpaceHide={decimalSpaceHide} strikeMSRP={strikeMSRP} useCompactCards={useCompactCards}/>
    </div>
  ]
    if (typeof window !== 'undefined'){
        window.DMFilteredInventory = vehicle;
    }
  if(pageContent.hasOwnProperty("ArrayRawHTML")) {
    const { arrayRawHTML } = pageContent.ArrayRawHTML
    const rawComponents = arrayRawHTML.map(element => {  
        return <HTML data={{ 
            order: Number(element.order),
            visible: element.visible,
            rawHTML: element.rawHtml
        }} key={"HTML"} />
    })
    components.push(...rawComponents)
  }
  let sortedComponents = components.sort((a, b) =>
    parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  ) // sorts the components based on json data
  // filters out the components that are not visible
  let visibleComponents = sortedComponents.filter(component => {
    return component.props.data.visible === true
  })

  // if vehicle array is empty, render message and cta 
  if (!pageContext.vehicle || pageContext.vehicle.length === 0) {
      const noInventoryComponent = (
        <div className="no-inventory-message" style={{textAlign: 'center', margin: '5em 1em 0'}}>
          <h1>No inventory available for {pageContext.title}</h1>
          <div className="no-inventory-cta" style={{
            backgroundColor: 'var(--primary-accent)', 
            borderRadius: '2px',
            cursor: 'pointer',
            width: '175px',
            height: '40px',
            margin: '2em auto 3em',
            padding: '10px'
         }}>
          <a href="/search/?" onclick="event.preventDefault();window.gatsbyNavigate('/search/?')" style={{color: 'var(--primary-text)'}}>
            View All Inventory
            </a>
          </div>
        </div>
      )
    visibleComponents.push(noInventoryComponent)
  }

  function insertUrlParam(key, value) {
    if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        // searchParams.set("page",StateResults.page)
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        history.replaceState({path: newurl}, '', newurl);
    }
}

useEffect(() => {
    if(SearchParam === "")
        insertUrlParam('','')
},[SearchParam])

  return (
    <Layout>
      <HTML key={"HTML"} data={pageContent.HTML} theme={theme} dealer={dealerInfo} />
      <FinanceCalculatorProvider>
            <VDPModalContainer
              siteData={{
                lead_url, site_id, gtmId,
                disclaimerContact, disclaimerFinanceApp, disclaimerCalculator
              }}
              financeData={{ rates, downpayment, defaultMonth }}
            />
            <div className="inventory-html-wrapper" style={{minHeight: '60vh'}}>
              {visibleComponents}
            </div>
        </FinanceCalculatorProvider>
      <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} tagString={headerTags} />
    </Layout>
  )
}
export default InventoryHTML